import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  components: {},
  data() {
    return {
      model: {
        user: "",
        claims: [],
        token: ""
      }
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile" }]);

    this.model.user = this.currentUser.user;
    this.model.claims = await Object.entries(this.model.user).map(entry => ({
      claim: entry[0],
      value: entry[1]
    }));

    this.model.token = this.currentUser ? this.currentUser.token : "";
  },
  computed: {
    ...mapGetters(["currentUser"])
  }
};
